import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { accountService } from "../../_services";
import moment from "moment";
import "./List.css";

function List({ match }) {
  const { path } = match;
  const [users, setUsers] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default 10 rows per page
  

  useEffect(() => {
    accountService.getAll().then((x) => setUsers(x));
  }, []);

  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setShowDialog(true);
  };

  const confirmDelete = () => {
    const id = selectedUser.id;
    setUsers(
      users.map((x) => {
        if (x.id === id) {
          x.isDeleting = true;
        }
        return x;
      })
    );
    accountService.delete(id).then(() => {
      setUsers((users) => users.filter((x) => x.id !== id));
      setShowDialog(false); 
      setShowSuccessMessage(true); 
      setTimeout(() => setShowSuccessMessage(false), 3000); 
    });
  }

  const cancelDelete = () => {
    setShowDialog(false);
  };

  // Handle status change
  const handleStatusChange = (userId, newStatus) => {
    const updatedUsers = users.map((user) => {
      if (user.id === userId) {
        user.status = newStatus;
      }
      return user;
    });
    setUsers(updatedUsers);
    // You might want to update this change on the server side as well
    // accountService.update(userId, { status: newStatus });
  };

  // Pagination logic
  console.log(users);
  const filteredUsers = users
    &&users.filter((user) => !user.parentUser)
    .filter((user) => {
      const fullName = `${user.title} ${user.firstName} ${user.lastName}`.toLowerCase();
      return (
        fullName.includes(searchQuery.toLowerCase()) ||
        user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.role.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });

     console.log(filteredUsers);

  const indexOfLastUser = currentPage * rowsPerPage;
  const indexOfFirstUser = indexOfLastUser - rowsPerPage;
  const currentUsers = filteredUsers && filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const totalPages = filteredUsers && Math.ceil((filteredUsers.length || 0) / rowsPerPage);

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when changing rows per page
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
        <input
          type="text"
          placeholder="Search"
          className="form-control"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            width: "280px",
            marginRight: "auto",
            paddingLeft: "20px",
          }}
        />
        <Link
          style={{ float: "right" }}
          to={`${path}/add`}
          className="btn-custom"
        >
          Add User
        </Link>
      </div>

      {showSuccessMessage && (
        <div className="alert alert-success" role="alert">
          User deleted successfully!
        </div>
      )}

      <table className="table table-striped">
        <thead>
          <tr>
            <th style={{ width: "20%" }}>Name</th>
            <th style={{ width: "20%" }}>Email</th>
            <th style={{ width: "15%" }}>Role</th>
            <th style={{ width: "15%" }}>Status</th>
            <th style={{ width: "15%" }}>Join On</th>
            <th style={{ width: "15%" }}></th>
          </tr>
        </thead>
        <tbody>
          {currentUsers && currentUsers.length > 0 ? (
            currentUsers.map((user) => (
              <tr key={user.id}>
                <td style={{ fontWeight: "bold" }}>
                  {user.title} {user.firstName} {user.lastName}
                </td>
                <td>{user.email}</td>
                <td>
                  {/^[a-f0-9]{24}$/i.test(user.role) ? (
                    <span className="role-chip member-chip">Member</span>
                  ) : user.role.toUpperCase() === "ADMIN" ? (
                    <span className="role-chip admin-chip">Admin</span>
                  ) : user.role.toUpperCase() === "SUPER-ADMIN" ? (
                    <span className="role-chip super-admin-chip">Super Admin</span>
                  ) : (
                    <span className="role-chip member-chip">"MEMBER"</span>
                  )}
                </td>

                <td>
                    <span className="status-chip status-chip-demo">{user.status && user.status.toUpperCase()}</span>
                  
                </td>

                <td style={{ whiteSpace: "nowrap", fontSize: "0.8em" }}>
                  {moment(user.createdDate).format("DD/MMM/YY HH:MM")}
                </td>

                <td style={{ whiteSpace: "nowrap" }}>
                  {user.role !== "SUPER-ADMIN" && (
                    <button
                      onClick={() => handleDeleteClick(user)}
                      className="btn btn-sm btn-danger mr-2"
                      disabled={user.isDeleting}
                    >
                      {user.isDeleting ? (
                        <span className="spinner-border spinner-border-sm"></span>
                      ) : (
                        <span>Delete</span>
                      )}
                    </button>
                  )}
                  <Link to={`${path}/view/${user.id}`} className="btn btn-sm btn-primary mr-2">
                    View
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">
                <span className="spinner-border spinner-border-lg align-center"></span>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="pagination-controls">
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "10px" }}>
          
          <button
            onClick={handlePreviousPage}
            className="btn btn-secondary btn-sm"
            disabled={currentPage === 1}
            style={{ minWidth: "80px" }}
          >
            Previous
          </button>
          <span style={{ marginRight: "10px", border: "0.5px solid black" , padding:"10px"}}>10</span> 


         

          <button
            onClick={handleNextPage}
            className="btn btn-secondary btn-sm"
            disabled={currentPage === totalPages}
            style={{ minWidth: "80px" }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export { List };
